.bigArrow {
  color: rgba(232, 64, 55, 1);
  border-color: rgba(35, 157, 219, 0);
  background-color: rgba(189, 225, 244, 0);
  background-image: none;
  border-width: 0;
  border-style: solid;
  width: 48px;
  height: 48px;
  font-size: 48px;
  padding: 0px;
  border-radius: 0;
  stroke-width: 0;
  transition: all 0.5s ease-in-out;
  transition-property: background, border-radius, color, border-color,
    box-shadow;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition-property: background, border-radius, color, border-color,
    box-shadow;
  -moz-transition-property: background, border-radius, color, border-color,
    box-shadow;
}

.bigArrowSVG {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor;
}
