.bg2 {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 600px;

  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  filter: brightness(132%) hue-rotate(217deg) saturate(156%) contrast(130%);
  background-position: 60% 50%;
  background-size: cover;
  z-index: -1;
}

.bg3 {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 0px;

  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  filter: brightness(132%) hue-rotate(217deg) saturate(156%) contrast(130%);
  background-position: 100% 50%;
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
}
