.proceedIcon {
  -webkit-box-flex: 0;
  flex-grow: 0;
  font-size: 31px;
  margin-right: 20px;
  margin-left: 0;
  stroke-width: 1.1;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor;
}
