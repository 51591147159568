.arrowSVG {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor;
  margin-left: 5px;
}
