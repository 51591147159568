.labelIcon {
  font-size: 32px;
  transform: translate(0, 0);
  fill: none;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  color: currentColor;
  top: 10px;
  left: -8px;
}

.searchIcon {
  -webkit-box-flex: 0;
  flex-grow: 0;
  font-size: 31px;
  margin-right: 20px;
  margin-left: 0;
  stroke-width: 1.1;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor;
}
