@import url(https://fonts.googleapis.com/css2?family=Overpass:wght@300&display=swap);
body {
  margin: 0;
  font-family: 'Overpass', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4d4846;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.arrowSVG {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor;
  margin-left: 5px;
}

.bg1 {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 600px;

  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  -webkit-filter: brightness(200%) hue-rotate(220deg) saturate(104%) contrast(109%);

          filter: brightness(200%) hue-rotate(220deg) saturate(104%) contrast(109%);
  background-position: 51% 0%;
  background-size: cover;
  z-index: -1;
}

.greyLine {
  border-top: 2px solid rgba(102, 102, 102, 0.1);
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  background: none;
  width: 100%;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 20px;
}

.bigArrow {
  color: rgba(232, 64, 55, 1);
  border-color: rgba(35, 157, 219, 0);
  background-color: rgba(189, 225, 244, 0);
  background-image: none;
  border-width: 0;
  border-style: solid;
  width: 48px;
  height: 48px;
  font-size: 48px;
  padding: 0px;
  border-radius: 0;
  stroke-width: 0;
  transition: all 0.5s ease-in-out;
  transition-property: background, border-radius, color, border-color,
    box-shadow;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition-property: background, border-radius, color, border-color,
    box-shadow;
  -moz-transition-property: background, border-radius, color, border-color,
    box-shadow;
}

.bigArrowSVG {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor;
}

.labelIcon {
  font-size: 32px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  fill: none;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  color: currentColor;
  top: 10px;
  left: -8px;
}

.searchIcon {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  font-size: 31px;
  margin-right: 20px;
  margin-left: 0;
  stroke-width: 1.1;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor;
}

.bg2 {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 600px;

  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  -webkit-filter: brightness(132%) hue-rotate(217deg) saturate(156%) contrast(130%);

          filter: brightness(132%) hue-rotate(217deg) saturate(156%) contrast(130%);
  background-position: 60% 50%;
  background-size: cover;
  z-index: -1;
}

.bg3 {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 0px;

  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  -webkit-filter: brightness(132%) hue-rotate(217deg) saturate(156%) contrast(130%);

          filter: brightness(132%) hue-rotate(217deg) saturate(156%) contrast(130%);
  background-position: 100% 50%;
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
}

.checkmark {
  color: rgba(232, 64, 55, 1);
  border-color: rgba(35, 157, 219, 0);
  background-color: rgba(189, 225, 244, 0);
  background-image: none;
  border-width: 0;
  border-style: solid;
  width: 22px;
  height: 22px;
  font-size: 22px;
  padding: 0px;
  border-radius: 0;
  stroke-width: 0;
  transition: all 0.5s ease-in-out;
  transition-property: background, border-radius, color, border-color,
    box-shadow;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition-property: background, border-radius, color, border-color,
    box-shadow;
  -moz-transition-property: background, border-radius, color, border-color,
    box-shadow;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
}

.proceedIcon {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  font-size: 31px;
  margin-right: 20px;
  margin-left: 0;
  stroke-width: 1.1;
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke: none;
  max-width: none;
  position: relative;
  fill: currentColor;
  color: currentColor;
}

