.bg1 {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 600px;

  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  filter: brightness(200%) hue-rotate(220deg) saturate(104%) contrast(109%);
  background-position: 51% 0%;
  background-size: cover;
  z-index: -1;
}

.greyLine {
  border-top: 2px solid rgba(102, 102, 102, 0.1);
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  background: none;
  width: 100%;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 20px;
}
